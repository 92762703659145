import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setRoom } from 'redux/actions'
import { Container, DefaultIconContainer,Border } from './styles'
import colors from 'constants/colors'
import {
  Flag,
  Hand,
  Person,
  Splash,
  UpArrowIcon,
  DownArrowIcon,
  SaunaRoomIcon,
  FemaleIcon,
  MaleIcon,
  GymIcon,
} from 'assets/icons';
import OutOfOrderIcon from 'assets/icons/OutOfOrder.svg'

const Room = ({
  children,
  width,
  height,
  roomData = {},
  disabled,
  className,
  roomName,
  floorBackgroundColor,
  roomNumber
}) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const selectedRoom = useSelector(state => state.selectReducer.selectedRoom)
  const { roomStatusHistories} = roomData
  const status = roomStatusHistories?.[0]?.status

  const StatusIcon = () => {

    if(roomName?.length > 0) {

    } else {
      switch (status) {
        case 'OCCUPIED':
        case 'OCCUPIED_CHECK_OUT_TODAY':
        case 'OCCUPIED_STAYOVER':
          return (      
            <DefaultIconContainer>
              <Person className="icon" />
            </DefaultIconContainer>
          )
        case 'INSPECTED':
          return ( 
            <DefaultIconContainer>
              <Flag className="icon" />
            </DefaultIconContainer>
          )
        case 'CLEAN':
          return (
            <DefaultIconContainer>
              <Hand className="icon" />
            </DefaultIconContainer>
          )
        case 'OUT_OF_ORDER':
          return (
            <DefaultIconContainer>
              <img src={OutOfOrderIcon} alt={'OutOfOrder'} width={9} height={9}/>
            </DefaultIconContainer>
          )
        case 'DIRTY':
          return (
            <DefaultIconContainer>
              <Splash className="icon" />
            </DefaultIconContainer>
          )
        default:
          return null
      }
    }
  }

  let backgroundColor
  switch (status) {
    case 'OCCUPIED':
      backgroundColor = colors.blue6;
      break;
    case 'OCCUPIED_CHECK_OUT_TODAY':
      backgroundColor = colors.pink1;
      break;
    case 'OCCUPIED_STAYOVER':
      backgroundColor = colors.red2;
      break;
    case 'INSPECTED':
      backgroundColor = colors.green6;
      break;
    case 'CLEAN':
      backgroundColor = colors.blue7;
      break;
    case 'OUT_OF_ORDER':
      backgroundColor = colors.black6;
      break;
    case 'DIRTY':
      backgroundColor = colors.brown2;
      break;
    default:
      backgroundColor = colors.grey21;
  }

  const RoomDefaultIcon = () => {
    if (roomName?.length > 0) {
      switch (roomName) {
        case 'saunaRoom':
          return (
            <DefaultIconContainer width="13px" height="13px">
              <SaunaRoomIcon className="icon" />
            </DefaultIconContainer>
          );
        case 'femaleLockerRoom':
        case 'maleLockerRoom':
        case 'gym':
          return (
            <DefaultIconContainer width="15px" height="23px">
              {roomName === 'femaleLockerRoom' ? (
                <FemaleIcon className="icon" />
              ) : roomName === 'maleLockerRoom' ? (
                <MaleIcon className="icon" />
              ) : roomName === 'gym' ? (
                <GymIcon className="icon" />
              ) : null}
            </DefaultIconContainer>
          );
        case 'leftElevator':
        case 'rightElevator':
          return (
            <DefaultIconContainer width="10px" height="10px">
              {roomName === 'leftElevator' ? (
                <UpArrowIcon className="icon" />
              ) : (
                <DownArrowIcon className="icon" />
              )}
            </DefaultIconContainer>
          );
        default:
          return null;
      }
    }
  }

  return (
    <>
      <Container
        className={className}
        roomName={roomName}
        roomNumber={roomNumber}
        onClick={
          disabled
            ? null
            : () => {
                if(roomNumber) {
                  dispatch(setRoom(roomNumber))
                  history.push(`/room/${roomNumber}`)
                }
              }
        }
        backgroundColor={backgroundColor}
        floorBackgroundColor={floorBackgroundColor}
        width={width}
        height={height}
        selected={selectedRoom === roomNumber}>
        {RoomDefaultIcon()}
        {StatusIcon()}
        {children}
      </Container>
      {
        selectedRoom === roomNumber? (
          <Border roomNumber={roomNumber}/>
        ): null
      }
    </>
  )
}

export default Room;
