import React from 'react';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import { useMutation } from '@apollo/client';
import { UPDATE_GROUP_BY_GROUP_ID } from 'graphql/service';
import { Container } from './styles';

const PointOfContact = ({
  contactName,
  organization,
  setContactName,
  setOrganization,
  groupId
}) => {

  const [updateGroupByGroupId] = useMutation(UPDATE_GROUP_BY_GROUP_ID)

  const handleSaveChanges = () => {
    updateGroupByGroupId({
      variables: {
        groupId,
        input: {
          contactName,
          organization
        }
      }
    })
  }

  return (
    <Card
      additionalStyle={`
        padding: 10px 15px;
      `}
    >
      <CardHeader
        additionalStyle={`
          @media only screen and (max-width: 767px) {
            
            .type {
              width: 100%;
              margin-bottom: 10px; 
            }

            .buttonContainer {
              width: 100%;
              justify-content: space-between;
            }
            
            flex-direction: column;
            justify-content: center;
          }
        `}
      >
        <div className='type'>POINT OF CONTACT</div>
        <div className='buttonContainer'>
          <Button
            mainPage
            success
            onClick={handleSaveChanges}
            additionalStyle={`
              width: 122px;

              @media only screen and (max-width: 767px) {
                width: 110px;
              }
            `}
          >
            Save Changes
          </Button>
        </div>
      </CardHeader>
      <Container>
        <Input
          mainPage
          error={!contactName}
          title="NAME"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          additionalStyle={`
            flex-basis: 49%;
            margin-right:2%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-right: 0;
            }
          `}
        />
        <Input
          mainPage
          error={!organization}
          title="ORGANIZATION"
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
          additionalStyle={`
            flex-basis: 49%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-top: 25px;
            }
          `}
        />
      </Container>
    </Card>
  );
};

export default PointOfContact;
