import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ModalCheckOut from 'screens/ModalCheckOut';
import ModalCheckIn from 'screens/ModalCheckIn';
import CreditCards from 'components/BookingMain/CreditCards';
import AddNewCreditCard from 'components/BookingMain/AddNewCreditCard';
import BookingInformation from './BookingInformation';
import GroupInformation from './GroupInformation';
import GeneralGroupNotes from './GeneralGroupNotes';
import MetaInformation from 'components/BookingMain/MetaInformation';
import PointOfContact from 'components/BookingMain/PointOfContact';
import GroupTotal from 'components/BookingMain/GroupTotal';
import { useQuery, useMutation } from '@apollo/client';
import {
  UPDATE_BOOKING_BY_BOOKING_ID,
  UPDATE_GROUP_BY_GROUP_ID,
  UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID,
  GET_GROUP_DETAILS_BY_GROUP_ID
} from 'graphql/service';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const BookingGroup = () => {

  const history = useHistory()
  const groupId = useParams().groupId

  //CreditCards
  const [paymentCards, setPaymentCards] = useState([])

  //BookingInformation
  const [bookingInfoObj, setBookingInfoObj] = useState({})

  //Two dimensional array of bills
  const [billsArray, setBillsArray] = useState([])
  const [updateBookingByBooingId] = useMutation(UPDATE_BOOKING_BY_BOOKING_ID)
  const [updateRoomBooking, {error: updateRoomBookingError}] = useMutation(UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID)

  //PointOfContact
  const [contactName, setContactName] = useState('')
  const [organization, setOrganization] = useState('')
  const [updateGroupByGroupId] = useMutation(UPDATE_GROUP_BY_GROUP_ID)

  //GeneralGroupNotes
  const [groupNotes, setGroupNotes] = useState([])
  const [groupName, setGroupName] = useState([])

  const { data: groupData, loading, error, refetch } = useQuery(GET_GROUP_DETAILS_BY_GROUP_ID, {
    variables: { groupId: groupId },
    onCompleted: (groupData) => {
      if(groupData) {
        const newObj = {}
        let newBillsArray = []
        const { name, bookings, groupNotes, tenants, paymentCards } = groupData.groupByID
        setGroupNotes(groupNotes)
        setGroupName(name)
        setPaymentCards(paymentCards || [])
        bookings.forEach((booking) => {
          const {
            bookingId,
            isDeleted,
            checkInDate,
            checkOutDate,
            roomBookings,
            tenantGroup,
            bills
          } = booking  
          if(!isDeleted) {
            newBillsArray.push(bills)
            const {
              //ratePlanId,
              pricePerNight,
              room: {
                roomId,
                roomNumber,
                roomType,
                barrierFree
              }
            } = roomBookings[0]
            const bookingInfo = {
              bookingId: bookingId,
              guestName: tenantGroup?.tenant?.user?.fullName,
              isPrimaryTenant: tenantGroup?.isPrimaryTenant,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              price: pricePerNight,
              //ratePlanId: ratePlanId,
              roomNumber: roomNumber,
              roomId: roomId,
              isWheelchairAccessible: barrierFree,
              roomType: roomType,
              roomChanged: false,
              dateTimeChanged: false
            }    
            newObj[bookingId] = bookingInfo
          }
        })
        setBookingInfoObj(newObj)
        setBillsArray(newBillsArray)
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const bookingInformationArray = []
  Object.keys(bookingInfoObj).forEach((bookingId, index)=> {

    const setBookingInfo = (bookingInfo)=>{
      const newObj = {...bookingInfoObj}
      newObj[bookingId] = bookingInfo
      setBookingInfoObj(newObj)
    }

    bookingInformationArray.push(      
      <Container
        key={bookingId}
      >
        <BookingInformation
          bookingInfo={bookingInfoObj[bookingId]}
          setBookingInfo={setBookingInfo}
          index={index}
        />
      </Container>      
    )
  })

  const handleAddNewToGroup = () =>{
    // createGroupBooking({
    //   variables: {
    //     input: {
    //       booking: {
    //         groupId: groupId,
    //         checkInDate: moment().toDate(),
    //         checkOutDate: moment().add(1, 'days').toDate(),
    //       }
    //     }
    //   },
    // })
  }

  const handleSaveChanges = () => {
    
    // updateGroupByGroupId({
    //   variables: {
    //     groupId: groupId,
    //     input: {
    //       //FIXME: point of contact
    //     }
    //   }
    // })

    Object.keys(bookingInfoObj).forEach((bookingId) => {
      const guestInfo = bookingInfoObj[bookingId]
      const { checkInDate, checkOutDate, roomId, roomChanged, dateTimeChanged } = guestInfo
      
      if(roomChanged) {
        updateRoomBooking({
          variables: {
            input: {
              roomId,
              bookingId
            },
          },
        })
      }

      if(dateTimeChanged) {
        if(moment(checkInDate) >= moment().toDate()) {
          updateBookingByBooingId({
            variables: {
              bookingId,
              input: {
                checkInDate,
                checkOutDate,
              }
            },
          })
        } else if(moment(checkOutDate) >= moment().toDate()) {
          updateBookingByBooingId({
            variables: {
              bookingId,
              input: {
                checkOutDate,
              }
            },
          })
        } 
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Group Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className='title'>
              <span
                className='highLight'
                onClick={() => {
                  history.push('/bookings')
                }}
              >
                Bookings
              </span>
              {` > ${groupName}`}
            </div>
            <div className='buttonContainer'>
              <ModalCheckIn
                disabled={true}
                buttonStyle={`
                  width: 143px;
                `}
                groupId={groupId}
              />
              <ModalCheckOut
                buttonStyle={`
                  width: 175px;
                  margin-left: 10px;
                `}
                groupId={groupId}
              />
            </div>
          </>
        }
      />
      <Content>
        <ContentColumn>
          <Container>
            <PointOfContact
              contactName={contactName}
              setContactName={setContactName}
              organization={organization}
              setOrganization={setOrganization}
              groupId={groupId}
            />
          </Container>
          <Container>
            <CreditCards paymentCards={paymentCards} refetch={refetch}/>
            <AddNewCreditCard groupId={groupId}/>
          </Container>
          <Container>
            <GroupTotal
              group={groupData.groupByID}
              refetch={refetch}
              groupId={groupId}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
            <GroupInformation
              group={groupData.groupByID}
            />
          </Container>   
          {
            bookingInformationArray
          }
          <Container>
            <GeneralGroupNotes
              notes={groupNotes}
              groupId={groupId}
              headerString={'GENERAL GROUP NOTES'}
              refetch={()=>{
                refetch().then((groupData) => {
                  setGroupNotes(groupData.data.groupByID?.groupNotes)
                })
              }}
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
        </ContentColumn>
      </Content>
      <Footer />
    </>
  )
}

export default BookingGroup;