import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import { OutOfOrderIcon } from 'assets/icons';
import { ClientInfoModalContainer } from './styles';
import { useHistory } from 'react-router-dom';
import ModalBookingCancel from 'screens/ModalBookingCancel';
import ModalCheckIn from 'screens/ModalCheckIn';
import moment from 'moment';

const ClientModal = ({
  clientModalOpen,
  setClientModalOpen,
  bookingDetailsData
}) => {
  const history = useHistory(); 
  const [top, setTop] = useState(50)

  useEffect(() => {
    const onScroll = () => {
      if(window.scrollY<50) {
        setTop(50-window.scrollY);
      } else {
        setTop(0)
      }
    }

    window.addEventListener('scroll', onScroll, { passive: true });
    // clean up code
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <ClientInfoModalContainer open={clientModalOpen} position={top}>
      <div>
        <OutOfOrderIcon
          style={{ width: '20px', height: '20px', marginBottom: '20px' }}
          onClick={() => {
            setClientModalOpen(false);
          }}
        />
      </div>
      <div className="clientButtonSection">
        <div className="clientName">
          {bookingDetailsData?.bookingByID?.fullName}
        </div>
        <div className="buttonsContainer">
          <Button
            mainPage
            additionalStyle={`
              width: 113px;
            `}
            onClick={() => {
              history.push(`/booking-details/${bookingDetailsData?.bookingByID?.bookingId}`);
            }}
          >
            Manage
          </Button>
          <ModalCheckIn
            buttonStyle={`
              width: 113px;
              margin-left: 5px;
            `}
            reservationCode={bookingDetailsData?.bookingByID?.reservationCode}
          />
          <ModalBookingCancel
            buttonLabel="Cancel"
            buttonStyle={`
              width: 88px;
              margin-left: 5px;
            `}
            danger
            bookingId={bookingDetailsData?.bookingByID?.bookingId}
          />
        </div>
      </div>
      <div>
        <div className="clientInfoItem">
          <div className="infoTitle">Confirmation number</div>
          <div className="info">
            {bookingDetailsData?.bookingByID?.reservationCode}
          </div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Status</div>
          <div className="info">{bookingDetailsData?.bookingByID?.status}</div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Group</div>
          <div className="info">
            {bookingDetailsData?.bookingByID?.group.name}
          </div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Arrival</div>
          <div className="info">
            {moment(bookingDetailsData?.bookingByID?.checkInDate).format("M/D/YYYY h:mm A")}
          </div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Departure</div>
          <div className="info">
            {moment(bookingDetailsData?.bookingByID?.checkOutDate).format("M/D/YYYY h:mm A")}
          </div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Room Type</div>
          <div className="info">
            {bookingDetailsData?.bookingByID?.roomBookings?.[0].room.roomType.name}
          </div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Assigned room</div>
          <div className="info">
            {bookingDetailsData?.bookingByID?.roomBookings?.[0].room.roomNumber}
          </div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Rate</div>
          <div className="info">
            $
            {bookingDetailsData?.bookingByID?.roomBookings?.[0].pricePerNight}
          </div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Origin</div>
          <div className="info">Email</div>
        </div>
        <div className="clientInfoItem">
          <div className="infoTitle">Created</div>
          <div className="info">
            {moment(bookingDetailsData?.bookingByID?.createdAt).format('M/DD/YYYY hh:mmA')}
          </div>
        </div>
      </div>
    </ClientInfoModalContainer>
  );
};

export default ClientModal;
