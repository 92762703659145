import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { CREATE_BOOKING_CHECK_IN } from 'graphql/service';
import { useMutation } from '@apollo/client';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalCheckIn = ({hidden, disabled, buttonLabel="Group Check In", buttonStyle, reservationCode}) => {
    
  const [isOpen, setIsOpen] = useState(false)
  const [createBookingCheckIn] = useMutation(CREATE_BOOKING_CHECK_IN)  

  const handleCheckIn = () => {
    createBookingCheckIn({
      variables: {
        reservationCode
      },
    })
    setIsOpen(false)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      disabled={disabled}
      additionalStyle={`
        max-width: 516px;
        .modal-content{
          height: 204px;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            top: 40px;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <div>Are you sure you want to check in?</div>
        <Button
          success
          additionalStyle={`
            height: 50px;
            width: 130px;
          `}
          onClick={handleCheckIn}
        >
          Check In
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalCheckIn;
