import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { useMutation } from '@apollo/client';
import { CREATE_DISCOUNT } from 'graphql/service';
import { Head, Container, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalAddDiscount = ({hidden, buttonStyle, groupId, bookingId, refetch}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [discountType, setDiscountType] = useState('other')
  const [discountDetails, setDiscountDetails] = useState('')
  const [discountAmount, setDiscountAmount] = useState('')
  const [createDiscount] = useMutation(CREATE_DISCOUNT, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const handleAddDiscount = () => {

    createDiscount({
      variables: {
        input: {
          charge: {
            type: discountType,
            note: discountDetails,
            bookingId: bookingId,
          }
        },
      },
    })
    setIsOpen(false)
  }
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Add Discount"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 444px;
        .modal-content{
          height: 373px;
          .close {
            z-index: 200;
          }
          .modal-body{
            position: relative;
            top: 20px;
            height: 100%;
          }
        }
        top: 139px;
      `}
    >
      <Head>Add Discount</Head>
      <Container>
        <TextArea      
          title="DISCOUNT DETAILS"
          rows={4}
          value={discountDetails}
          onChange={(e) => setDiscountDetails(e.target.value)}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
      </Container>
      <Footer>
        <Input
          value={discountAmount}
          title="AMOUNT"
          onChange={(e) => setDiscountAmount(e.target.value)}
          additionalStyle={`
            flex-basis: 31%;
          `}
        />
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleAddDiscount}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalAddDiscount;
