import React from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FloorCard from './components/FloorCard';
import Alerts from './components/Alerts';
import Messages from './components/Messages';
import { useQuery } from '@apollo/client';
import { GET_ALL_ROOMS } from 'graphql/service';
import {
  Content,
  ContentColumn,
  Container,
} from './styles';

const Home = () => {

  const { data: roomsData, loading, error } = useQuery(GET_ALL_ROOMS, {
    pollInterval: 60000,
  });
  
  if (error) {
    return <div>Error! {error.message}</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  const eighthFloorRooms = {}
  const ninthFloorRooms = {}
  const tenthFloorRooms = {}
  roomsData.validRooms.forEach(room => {
    switch (room.floor) {
      case '8':
        eighthFloorRooms[room.roomNumber] = room
        break
      case '9':
        ninthFloorRooms[room.roomNumber] = room
        break
      case '10':
        tenthFloorRooms[room.roomNumber] = room
        break
      default:
        break
    }
  })
  
  return (
    <>
      <Helmet>
        <title>Home - Rook Hotel PMS</title>
      </Helmet>
      <Header
        additionalStyle={`
          border: none;
        `}
      />
      <Content>
        <ContentColumn>
          <Container>
            <FloorCard eighthFloorRooms={eighthFloorRooms} ninthFloorRooms={ninthFloorRooms} tenthFloorRooms={tenthFloorRooms}/>
          </Container>
        </ContentColumn>
        <ContentColumn desktop>
          <Container>
            <Alerts />
          </Container>
        </ContentColumn>
        <ContentColumn desktop>
          <Container>  
            <Messages />
          </Container>
        </ContentColumn>
      </Content>
      <Footer />
    </>
  )
}

export default Home;